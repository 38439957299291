import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo, useState } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  title?: string
}

export const WellnessArea = memo(function WellnessArea({
  items,
  title,
}: Props) {
  if (!items) {
    return null
  }

  const [itemActive, setActive] = useState(null)
  const [firstItemActive, setFirstItemActive] = useState(true)
  const activeItem = (e: any) => {
    const {
      target: {
        dataset: { active },
      },
    } = e

    if (active) setActive(active)
  }

  return (
    <Container row stretch tag="section" wrap>
      <LeftSide>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        <FadeInUp>
          {items.map((item, index) => (
            <NavItem key={index}>
              <NavInner
                className={
                  itemActive === `${index}` || (firstItemActive && index === 0)
                    ? 'active'
                    : undefined
                }
                data-active={index}
                onClick={(e: any) => {
                  activeItem(e)
                  setFirstItemActive(false)
                }}
              >
                {item.title}
              </NavInner>
            </NavItem>
          ))}
        </FadeInUp>
      </LeftSide>
      <RightSide>
        {items.map((item, index) => (
          <Item
            className={
              itemActive === `${index}` || (firstItemActive && index === 0)
                ? 'active'
                : undefined
            }
            key={index}
            {...item}
          />
        ))}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)``

const LeftSide = styled.div`
  width: 43%;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  padding: 5.1875rem 11.667vw 6rem;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3rem 1.9375rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.25rem;
  margin-bottom: 3.75rem;

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
    margin-bottom: 1.5rem;
  }
`

const NavItem = styled.div``

const NavInner = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 800;
  letter-spacing: 0.075rem;
  line-height: 0.9375rem;
  opacity: 0.3;
  margin-top: 1.5rem;
  position: relative;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  &:hover {
    opacity: 1;
  }
  &:after {
    content: '';
    display: block;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    bottom: -0.1875rem;
    right: 100%;
    left: 0;
    transition: 0.3s ease-in-out;
  }
  &.active {
    opacity: 1;
    &:after {
      right: 0;
    }
  }
`

const RightSide = styled.div`
  width: 57%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    backface-visibility: hidden;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &:after {
    content: '';
    width: calc(100% - 3.75rem);
    height: calc(100% - 3.75rem);
    backface-visibility: hidden;
    border: 2px solid ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.1;
    position: absolute;
    top: 1.875rem;
    left: 1.875rem;
    z-index: 2;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-bottom: 70%;
    &:after {
      display: none;
    }
  }
`
