import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  cta?: ButtonProps
  mainImage?: ImageProps
  services: ServiceProps[]
  thumbnails?: ImageProps[]
  title?: string
}

export const WellnessServices = memo(function WellnessServices({
  cta,
  mainImage,
  services,
  thumbnails,
  title,
}: Props) {
  return (
    <Container row space="between" stretch tag="section" wrap>
      <LeftSide>{mainImage ? <LazyLoadImage {...mainImage} /> : null}</LeftSide>
      <RightSide>
        {thumbnails ? (
          <Thumbnails row space="between" wrap>
            {uniq(thumbnails).map((item, index) => (
              <Thumb key={index}>
                <LazyLoadImage effect="blur" {...item} />
              </Thumb>
            ))}
          </Thumbnails>
        ) : null}
        <Wrapper>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          <Services row wrap stretch>
            {services.map((item, index) => (
              <Service key={index} {...item} />
            ))}
          </Services>
          {cta ? (
            <FadeInUp>
              <CTA {...cta} />
            </FadeInUp>
          ) : null}
        </Wrapper>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  padding-bottom: 6.25rem;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    height: calc(100% - 8.0625rem);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    padding-bottom: 0;
    &:before {
      display: none;
    }
  }
`

const LeftSide = styled.div`
  width: calc(50% - 0.75rem);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-bottom: 70%;
  }
`

const RightSide = styled.div`
  width: calc(50% - 0.75rem);
  padding-right: 11.667vw;

  @media (max-width: 1023px) {
    width: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    padding: 3rem 1.9375rem;
  }
`

const Thumbnails = styled(FlexBox)`
  margin-bottom: 4.375rem;

  @media (max-width: 1023px) {
    display: none;
  }
`

const Thumb = styled.div`
  width: calc(50% - 0.75rem);
  padding-bottom: calc(50% - 0.75rem);
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Wrapper = styled.div`
  padding-left: 6.25vw;

  @media (max-width: 1023px) {
    padding-left: 0;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.125rem;
  line-height: 1.75rem;
  position: relative;
  z-index: 2;

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
`

const Services = styled(FlexBox)``

const CTA = styled(Button)`
  margin-top: 5.1875rem;
  padding-right: 3rem;
  padding-left: 3rem;

  @media (max-width: 1023px) {
    margin-top: 3.75rem;
  }
`
