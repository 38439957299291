import styled from '@emotion/styled'
import { BannerCTA } from 'app/components/BannerCTA'
import { GalleryImages } from 'app/components/GalleryImages'
import { Hero } from 'app/components/Hero'
import { Info } from 'app/components/Info'
import { OffersSlider } from 'app/components/OffersSlider'
import { SEO } from 'app/components/SEO'
import { SimpleIntro } from 'app/components/SimpleIntro'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { WellnessArea } from 'app/components/WellnessArea'
import { WellnessServices } from 'app/components/WellnessServices'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function WellnessPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      <GoogleAnalytics />
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.headerProps}
        />
      ) : null}
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.simpleIntroProps ? (
        <SimpleIntro {...context.simpleIntroProps} />
      ) : null}
      {context.wellnessServicesProps ? (
        <WellnessServices {...context.wellnessServicesProps} />
      ) : null}
      {context.wellnessAreaProps ? (
        <WellnessArea {...context.wellnessAreaProps} />
      ) : null}
      {context.infoProps ? <Info {...context.infoProps} /> : null}
      {context.galleryImagesProps ? (
        <GalleryImages {...context.galleryImagesProps} />
      ) : null}
      {context.offersSliderProps ? (
        <OffersSlider variant="2items" {...context.offersSliderProps} />
      ) : null}
      {context.bannerCTAProps ? (
        <BannerCTA {...context.bannerCTAProps} />
      ) : null}
      {context.footerProps ? (
        <Footer
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.footerProps}
        />
      ) : null}
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
